
export const isCompanyApp = true;

export const appSettings = {"companyId":"21303","companyHash":"pll","companyName":"Capitol City Oil","companyLogo":"assets/icons/company-logo.svg","isPropane":false,"subMenuLogo":"assets/icons/sub-menu-logo.svg","cookieDomain":".instient.com"};

export const domain = {"dev":"https://capitolcityoil.instient.com/","uat":"","main":""};

export const appStaticText = {"homeButtoTitle":"CHECK PRICE","oilSelectButtoTitle":"SEARCH","companyNumber":"12796"};

export const appHome = {"title":"Discount heating oil, delivered.","commitment":""};

export const appContactUs = {"pageTitle":"Contact","description":"Our customer care team welcomes your inquiries.","phone":2032910777,"phoneFormatted":"(203) 291 – 0777","mail":"support@heatfleet.com","sms":2032910777,"smsFormatted":"(203) 291 – 0777","officeHours":{"title":"Office Hours","weekDays":[{"day":"Monday","timing":"7:30am - 4:00pm"},{"day":"Tuesday","timing":"7:30am - 4:00pm"},{"day":"Wednesday","timing":"7:30am - 4:00pm"},{"day":"Thursday","timing":"7:30am - 4:00pm"},{"day":"Friday","timing":"7:30am - 4:00pm"},{"day":"Saturday","timing":"Closed"},{"day":"Sunday","timing":"Closed"}]},"headQuarters":[{"title":"Headquarters","address":["2220 Gettysburg Road","Camp Hill, PA 17011"]}],"maps":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3446.541619644679!2d-81.59111298523436!3d30.25014551578163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5ca0eebe30dc5%3A0x6709b36e45154e94!2s4651%20Salisbury%20Rd%2C%20Jacksonville%2C%20FL%2032256%2C%20USA!5e0!3m2!1sen!2sin!4v1662750572130!5m2!1sen!2sin","socialPlatform":{"title":"Follow us on","platform":[{"name":"facebook","link":"https://www.facebook.com/HeatFleet"},{"name":"instagram","link":"https://www.instagram.com/heatfleetoil/"},{"name":"linkedin","link":""}]}};

export const appAboutUs = {"pageTitle":"About","paragraph":"<p>Are you shopping for low-cost  heating oil delivery for your home or business without the long-term  contracts and fees? Capitol City Oil has you covered.</p><br><p>We’ve been serving  our will-call heating oil customers with reliable delivery in the greater Harrisburg area for over 20 years.</p><br><p>Our customer care team is standing by to take your call at <a class=\"contact-link\" href=\"tel:717-896-1417\">717-896-1417</a>. You can also text us at <a class=\"contact-link\" href=\"sms:717-737-4188\">717-737-4188.</a></p>","teamImageHeader":"The Capitol City Oil Team","teamImage":{"title":"","alt":"Photo of the staff and fuel oil delivery drivers at Capitol City Oil.","url":"assets/images/team-placeholder.svg"},"teamImageDescription":"Trust our family to keep your family warm, all winter long."};

export const appFAQ = {"pageTitle":"FAQs","faqs":[{"question":"What type of credit card can I use to purchase oil?","answer":"<p>We accept Visa, Mastercard and Discover.</p>"},{"question":"Why is there a minimum number of gallons I can order?","answer":"<p>We accept Visa, Mastercard and Discover.</p>"},{"question":"How soon will my oil be delivered?","answer":"<p>We accept Visa, Mastercard and Discover.</p>"}]};

export const appPrivacyPolicy = {"pageTitle":"Privacy Policy","content":"<p>Privacy Policy Text Here</p>"};

export const appTermsAndConditions = {"pageTitle":"Terms & Conditions","content":"<p>Terms & Conditions Text Here</p>"};

export const appFooter = {"address":["2220 Gettysburg Road","Camp Hill, PA 17011","HIC PA141364 | PA006911"],"phone":2032910777,"phoneFormatted":"(203) 291 – 0777","mail":"support@heatfleet.com","sms":2032910777,"smsFormatted":"(203) 291 – 0777","contactMessage":"Contact our Customer Care team","footerMessage":" ©2022 Capitol City Oil. All Rights Reserved. <a href=\"/privacy-policy\">Privacy Policy</a>. <a href=\"/terms-conditions\">Terms & Conditions.</a>"};

export const appHowItWorks = {"companyInfo1":"Capitol City Oil has provided the greater Harrisburg community with affordable, reliable will-call heating oil delivery for two decades and counting.","companyInfo2":"If you find a competitor delivering heating oil at a lower price than us, we’ll beat that price by 2¢, <span style=\"color: #CF2028;\" class=\"hf-dark-font\">GUARANTEED</span>!","howItWorks1":{"title":"Check today’s price","info":"Enter your ZIP code and order quantity for a real-time quote."},"howItWorks2":{"title":"Place your order","info":"Placing an order on our app takes two minutes or less."},"howItWorks3":{"title":"Receive your delivery","info":"We’ll deliver on or before the promised delivery date, and you don’t need to be home for delivery."},"dealAlerts":{"title":"Deal Alerts","subscribeDetails":"Subscribe to deal alerts and well send promotion announcements directly to your inbox."}};
